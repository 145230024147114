
import { defineComponent, ref, onMounted, computed } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import VueApexCharts from 'vue3-apexcharts';

interface BeneficiaryData {
  sector: string;
  male_count: number;
  female_count: number;
}

export default defineComponent({
  name: "EventGenderChart",
  components: {
    apexchart: VueApexCharts,
  },
  setup() {
    const list = ref<BeneficiaryData[]>([]);

    const chartOptions = computed(() => ({
      chart: {
        type: "bar",
        stacked: true,
        height: 500,
        toolbar: {
          show: true,
        },
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: "30%",
        },
      },
      xaxis: {
        categories: list.value
          .filter((item) => item.sector !== "Grand Total") // Exclude Grand Total
          .map((item) => item.sector),
      },
      yaxis: {
        title: {
          text: 'Number of Beneficiaries',
        },
      },
      legend: {
        position: "top",
      },
      fill: {
        opacity: 1,
      },
    }));


    const chartSeries = computed(() => [
    {
          name: 'Male',
          data: list.value
            .filter((item) => item.sector !== "Grand Total") // Exclude Grand Total
            .map((item) => item.male_count),
        },
        {
          name: 'Female',
          data: list.value
            .filter((item) => item.sector !== "Grand Total") // Exclude Grand Total
            .map((item) => item.female_count),
        },
    ]);

    const loadData = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DASHBOARD_SECTOR_OCCUPATION_WISE_BENEFICIARY,
          {}
        );

        list.value = response.data.data;

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(loadData);

    return {
      chartOptions,
      chartSeries,
      list,
    };
  },
});
