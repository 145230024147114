
import { defineComponent, ref, onMounted, computed, PropType, watch } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import VueApexCharts from 'vue3-apexcharts';

// Define an interface for the item structure
interface BeneficiaryData {
  company_status: string;
  male_count: number;
  female_count: number;
  beneficiary_ethinicity_count: number;
  is_disable_count: number;
}

// Define an interface for the chart series
interface ChartSeries {
  name: string;
  data: number[];
}

export default defineComponent({
  name: "EventGenderChartTraining",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    training_type: String,
    widgetClasses: String,
    genderData: {
      type: Array as PropType<{ label: string; data: number }[]>,
      required: true,
    },
  },
  setup(props) {
    const list = ref<BeneficiaryData[]>([]);
    const chartSeries = ref<ChartSeries[]>([ // Use the defined interface here
      { name: 'Male', data: [] },
      { name: 'Female', data: [] },
    ]);

    const chartOptions = ref({
      chart: {
        type: 'bar',
      },
      xaxis: {
        categories: [] as string[], // Categories will be populated from the list
      },
      dataLabels: {
        enabled: true,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '15%',
          dataLabels: {
            position: 'top',
          },
        },
      },
      title: {
        text: 'Beneficiaries by Apprenticeship Type',
        align: 'center',
      },
    });

    const title = computed(() => {
      return props.training_type === 'Formal'
        ? "Number of formal apprenticeship beneficiaries by sector"
        : "Number of informal apprenticeship beneficiaries by sector";
    });

    const loadData = async () => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DASHBOARD_TRAINING_INSTITUTE_EVENT_WISE_BENEFICIARY,
          { training_type: props.training_type }
        );

        // Set list for the table display, including "Grand Total"
        list.value = response.data.data;

        // Filter out "Grand Total" for the chart data
        const filteredData = list.value.filter(item => item.company_status !== 'Grand Total');

        if (filteredData.length > 0) {
          // Prepare new chart series and categories data
          const newChartSeries = [
            { name: 'Male', data: filteredData.map(item => item.male_count) },
            { name: 'Female', data: filteredData.map(item => item.female_count) },
          ];

          const newCategories = filteredData.map(item => item.company_status);

          // Update the chart options with new categories
          chartOptions.value = {
            ...chartOptions.value,
            xaxis: {
              ...chartOptions.value.xaxis,
              categories: newCategories,
            },
          };

          // Assign new chart series to trigger reactivity
          chartSeries.value = newChartSeries;

        } else {
          console.warn("No data available for the chart after filtering out 'Grand Total'");
          chartSeries.value = []; // Clear series if no data remains
        }

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    onMounted(loadData);

    return {
      chartOptions,
      chartSeries,
      list,
      title,
    };
  },
});
