
import { defineComponent, ref } from "vue";
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  name: "Trainer",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    type: String,
    widgetClasses: String,
  },
  setup() {
    // Static data with year-wise and women data
    const list = ref([
      { district: "Dhaka", trainerName: "Mr. Lee", gender: "Male", trainingName: "ProGRESS Planning workshop", individualsReceived: 20, year: 2021, womenPercentage: 60 },
      { district: "Dhaka", trainerName: "Mr. Alex", gender: "Male", trainingName: "Focus Group Discussion on Care Economy sector in Bangladesh", individualsReceived: 25, year: 2021, womenPercentage: 70 },
    ]);

    const chartOptions = ref({
      chart: {
        type: 'bar',
        height: 350,
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '15%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: list.value.map(item => `${item.trainerName} (${item.year})`), // Use trainer names and year as x-axis categories
      },
      title: {
        text: 'Individuals Receiving BDS by Trainer and Year',
        align: 'center',
      },
    });

    const chartSeries = ref([
      {
        name: 'Individuals Receiving BDS',
        data: list.value.map(item => item.individualsReceived), // Use individuals received for series data
      },
      {
        name: 'Women Percentage',
        data: list.value.map(item => item.womenPercentage), // Use women percentage for series data
      }
    ]);

    return {
      chartOptions,
      chartSeries,
      list,
    };
  },
});
