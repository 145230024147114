
import { defineComponent, ref, onMounted, PropType } from "vue";
import ApiService from "@/core/services/ApiService";
import { apiEndpoint } from "@/mixin/apiMixin";
import VueApexCharts from 'vue3-apexcharts';

// Define the type for the items in the list
interface BeneficiaryData {
  training_type: string;
  male_count: number;
  female_count: number;
  total_disable_count: number;
  total_ethnic_minorities: number;
}

// Define the type for chart series
interface ChartSeries {
  name: string;
  data: number[];
}

// Define the type for chart options
interface ChartOptions {
  chart: {
    type: string;
  };
  xaxis: {
    categories: string[];
  };
  dataLabels: {
    enabled: boolean;
  };
  title: {
    text: string;
    align: string;
  };
  plotOptions: {
    bar: {
      horizontal: boolean;
      columnWidth: string; // Change type to string for percentage
      dataLabels: {
        position: 'top'; // Position data labels above the bars
      };
    };
  };
}

export default defineComponent({
  name: "EventGenderChart",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    widgetClasses: String,
    genderData: {
      type: Array as PropType<{ label: string; data: number }[]>,
      required: true,
    },
  },
  setup(props) {
    const list = ref<BeneficiaryData[]>([]);
    const chartSeries = ref<ChartSeries[]>([]);

    const chartOptions = ref<ChartOptions>({
      chart: {
        type: 'bar',
      },
      xaxis: {
        categories: [],
      },
      dataLabels: {
        enabled: true, // Enable data labels
      },
      title: {
        text: 'Beneficiaries by Training Type',
        align: 'center',
      },
      plotOptions: {
        bar: {
          horizontal: false, // Set to true if you want a horizontal bar chart
          columnWidth: '40%', // Set bar width to 40%
          dataLabels: {
            position: 'top', // Position data labels above the bars
          },
        },
      },
    });

    const loadData = async (year: string) => {
      try {
        const response = await ApiService.post(
          apiEndpoint.data().VUE_APP_DASHBOARD_TRAINING_INSTITUTE_WISE_BENEFICIARY,
          { year }
        );

        // Set the full data list to include "Grand Total" for the table
        list.value = response.data.data;

        // Filter out "Grand Total" from the chart data
        const filteredList = list.value.filter(item => item.training_type !== "Grand Total");

        // Prepare the chart series based on the filtered data
        const maleData = filteredList.map(item => item.male_count);
        const femaleData = filteredList.map(item => item.female_count);
        chartSeries.value = [
          { name: 'Male', data: maleData },
          { name: 'Female', data: femaleData },
        ];

        // Update the x-axis categories with training types, excluding "Grand Total"
        chartOptions.value.xaxis.categories = filteredList.map(item => item.training_type);

      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    onMounted(() => loadData(''));

    return {
      chartOptions,
      chartSeries,
      list,
      loadData,
    };
  },
});
