
import { defineComponent, ref, computed } from "vue";
import VueApexCharts from 'vue3-apexcharts';

export default defineComponent({
  name: "TourismTourist",
  components: {
    apexchart: VueApexCharts,
  },
  props: {
    type: String,
    widgetClasses: String,
  },
  setup(props) {
    // Static data for the table and chart
    const list = ref([
      {
        district: "Barguna",
        organization: "In do molestiae veni",
        serviceName: "Totam inventore qui",
        location: "Laboriosam exercita",
        description: "Technology Solutions",
        year: 2020,
        typeOfSupport: "In totam placeat re",
        individualsReceived: 20,
      },
      {
        district: "Barguna",
        organization: "Autem in cupiditate",
        serviceName: "Officiis ad repellen",
        location: "Repudiandae minima i",
        description: "Technology Solutions",
        year: 2021,
        typeOfSupport: "Voluptas ab sunt quo",
        individualsReceived: 25,
      },
    ]);

    // Chart data setup
    const chartSeries = ref([{
      name: 'Individuals Received',
      data: list.value.map(item => item.individualsReceived), // Extract count for chart
    }]);

    const chartOptions = ref({
      chart: {
        height: 350,
        type: 'bar',
      },
      plotOptions: {
        bar: {
          horizontal: false,
          columnWidth: '15%',
          endingShape: 'rounded',
        },
      },
      dataLabels: {
        enabled: true,
      },
      xaxis: {
        categories: list.value.map(item => `${item.serviceName} (${item.year})`), // Use service names with year as categories
      },
      title: {
        text: 'Tourism Services Bar Graph',
        align: 'center',
      },
    });

    const title = computed(() => {
      return props.type === 'tourisam'
        ? "Number of tourism destination committee established and operational"
        : "Number of Tourist Destination Services/Facilities Improved";
    });

    return {
      chartOptions,
      chartSeries,
      list,
      title,
    };
  },
});
